const showreels = [
    {
        id: 1,
        name: "SHOWREEL GENERAL 2017-2025",
        vimeoId: 1054524995,
        
        credits: {
            music: 'DJ MarHan',
            da: 'Vanessa Bosio',
        }
    },
    {
        id: 2,
        name: "SHOWREEL 2024",
        vimeoId: 1072193386,
        
        credits: {
            music: 'DJ MarHan',
            da: 'Vanessa Bosio',
        }
    },
]


export default showreels
